import React, {useState} from 'react';
import {Button, Header, Table} from "@amzn/awsui-components-react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Logger from "../utils/logger";
import {getStage} from "../utils/environment";
import S3Logic from "../utils/s3Logic";
import CONSTANT, {PERM_ATTACHING_DOCUMENT_BUCKET_PREFIX} from "../utils/constant";
import {DOCUMENT_DATA} from "../config/table";
import {DocumentFeedbackMessage} from "./upload/documentUtils";
import {DeleteSingleDocumentModal} from "./deleteSingleDocumentModal";


/**
 * Display the records in the Document table.
 */
export const QueryDocumentData = (props) => {
    const user = localStorage.getItem(CONSTANT.LOCAL_STORAGE_USERID);
    const [selectedItems, setSelectedItems] = useState([]);
    const [deleteWindowVisible, setDeleteWindowVisible] = useState(false);
    const [feedbackStatus, setFeedbackStatus] = useState("");
    /**
     * Function for row click in the data table.
     * The elementSelected is the row selected.
     */
    const onRowClick = ({detail}) => {
        let newElementSelected = [...selectedItems];
        if (newElementSelected.includes(detail.item)) {
            newElementSelected = newElementSelected.filter(x => x !== detail.item)
        } else {
            newElementSelected.push(detail.item)
        }
        onSelectionChange({detail: {selectedItems: newElementSelected}});
    };
    const onSelectionChange = ({ detail }) => {
        setSelectedItems(detail.selectedItems)
        Logger.logInfo("selectItems:" + JSON.stringify(selectedItems));
        Logger.logInfo("Length of selectItems:" + selectedItems.length);
    }
    const handleDownloadClick = async () => {
        Logger.logInfo("handle download Click: " + + JSON.stringify(selectedItems));
        if (selectedItems.length !== 1) {
            return;
        }
        const versionId = selectedItems[0].versionId;
        const documentName = selectedItems[0].documentName;
        let stage = getStage();
        if (stage === "dev") {
            stage = "beta";
        }
        const bucket = PERM_ATTACHING_DOCUMENT_BUCKET_PREFIX + stage;
        Logger.logInfo("download Click data: " + documentName + " " + versionId + " " + bucket);
        setFeedbackStatus(CONSTANT.MODAL_DOWNLOAD_SUBMITTING)
        const urlResponse = await S3Logic.fetchPresignedUrl("GET", documentName, bucket, versionId);
        Logger.logInfo("URL Response: "+ JSON.stringify(urlResponse.url));
        if (!urlResponse?.url) { // If URL cannot be fetched, do not attempt execution
            setFeedbackStatus(CONSTANT.MODAL_DOWNLOAD_ERROR)
            return {
                status: urlResponse.status,
                errorMessage: "Unable to fetch URL, while attempting to retrieve validated file."
            };
        }
        const downloadLink = urlResponse.url;
        setFeedbackStatus(CONSTANT.MODAL_DOWNLOAD_SUCCESS)

        // auto download link sending to the computer.
        window.location.href = downloadLink;
    }

    const handleDeleteClick = () => {
        Logger.logInfo("handle delete Click: " + JSON.stringify(selectedItems));
        if (selectedItems.length !== 1) {
            return;
        }
        setSelectedItems(selectedItems);
        setDeleteWindowVisible(true);
    }

    const handleDismissDeleteClick = () => {
        setDeleteWindowVisible(false);
        setSelectedItems([]);
    }

    const handleCancelDeleteClick = () => {
        setDeleteWindowVisible(false);
    }

    return <>
        {
            deleteWindowVisible && <DeleteSingleDocumentModal user={user}
                                                              selectedItem={selectedItems}
                                                              dismissDeleteClick={handleDismissDeleteClick}
                                                              cancelDeleteClick={handleCancelDeleteClick}
                                                              deleteWindowVisible={deleteWindowVisible}
                                                              handleDismissDocumentModalClick={props.handleDismissDocumentModalClick}/>
        }
        <Modal
            onDismiss={props.handleDismissDocumentModalClick}
            visible={true}
            expandToFit={true}
            size="max"
            closeAriaLabel="Close modal"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={props.handleDismissDocumentModalClick}>Cancel</Button>
                        <Button variant="primary" disabled ={selectedItems.length !== 1} onClick={handleDeleteClick}>Delete</Button>
                        <Button variant="primary" disabled ={selectedItems.length !== 1} onClick={handleDownloadClick}>Download</Button>
                    </SpaceBetween>
                </Box>
            }
            header="Document(s)">
            <Table onRowClick={onRowClick}
                   onSelectionChange={onSelectionChange}
                   selectedItems={selectedItems}
                   columnDefinitions={DOCUMENT_DATA}
                   items={props.distributions}
                   stickyHeader={true}
                   resizableColumns={true}
                   header={<Header variant="h3">Document: </Header>}
                   wrapLines={true}
                   selectionType="multi"/>
            <br/>
            <br/>
            <DocumentFeedbackMessage status={feedbackStatus}/>
            <br/>
        </Modal>
    </>
}
