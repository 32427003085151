import React, {Component} from 'react';
import '@amzn/awsui-global-styles/polaris.css';
import {
    Button,
    Form,
    Header,
    Container,
    ButtonDropdown,
    SpaceBetween,
    Box,
    TextContent
} from "@amzn/awsui-components-react";
import CONSTANT, { IC_SETTLEMENTS_LDAP } from "../utils/constant";
import APIClient from "../utils/apiClient";
import PaymentGeneration from "../utils/paymentGeneration";
import Logger from "../utils/logger";
import {
    VISIBLE_COLUMN_OPTIONS_APPROVED_PAYMENT,
    HIDDEN_OPTIONS_PENDING_SETTLEMENTS,
    FILE_GENERATION_DROPDOWN_ITEMS,
    DOWNLOAD_SETTLEMENTS_DROPDOWN_ITEMS,
    AUTO_EXPORT_DROPDOWN_ITEMS
} from "../config/table";
import config from "../config/config.json"
import FormatData from "../utils/formatData";
import DataContentTable from "../component/dataContentTable";
import {getStage} from "../utils/environment";
import {DOWNLOAD_FILE_PAYMENT_DELIMITER,
    FX_FILE_PAYMENT_BOOKTYPE,
    FX_FILE_PAYMENT_HEADER,
    FX_FILE_PAYMENT_DELIMITER,
    WIRE_FILE_PAYMENT_HEADER,
    WIRE_FILE_PAYMENT_DELIMITER} from "../config/file";
import xlsxParser from "../utils/xlsxParser";
import {
    getFeedbackMessage,
    getLatestComment,
    populateRegion,
    lookupBranch
} from "./common/paymentRecordTableCommon";
import BatchUpdatePaymentModal from "./common/modal/batchUpdatePaymentModal";
import UpdatePaymentModal from "./common/modal/updatePaymentModal";
import DownloadPaymentModal from "./common/modal/downloadPaymentModal";
import ValidatePaymentExportModal from "./common/modal/validatePaymentExportModal";
import CommentModal from "./common/modal/commentModal";
import { ldapMembershipAlert } from '../utils/polarisAlerts';
import { arrayIntersection } from  '../utils/arrayIntersection';
import S3Logic from '../utils/s3Logic';
import {NoDocumentModal} from "../document/noDocumentModal";
import {QueryDocumentData} from "../document/queryDocumentData";
import {hasDocumentAttached} from "../document/upload/documentUtils";
import SingleDocumentModule from "../document/upload/singleDocumentModule";

/*
 * UI container that renders the approved payments data table and the action items bar.
 */
class ApprovedPaymentDataContentBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        Logger.logInfo("Intialize ApprovedPaymentDataContentBlock");
    }

    render() {
        let updateDisabled = true;
        let batchUpdateDisabled = true;
        let downloadDisabled = false;
        let commentsDisabled = true;
        let fileGenerationDisabled = true;

        let selectedElement = {};
        let updateWindow = "";
        let batchUpdateWindow = "";
        let viewDocumentDisabled = true;
        let viewDocumentButton = <></>;
        let singleUploadDisabled = true;

        // Determine whether user actions are allowed based on selected items.
        if (this.props.elementSelected.length > 0) {
            fileGenerationDisabled = false;
            if (this.props.elementSelected.length === 1) {
                updateDisabled = false;
                commentsDisabled = false;
                selectedElement = this.props.elementSelected[0];
                viewDocumentDisabled = false;
                singleUploadDisabled = false;
                const recordId = this.props.elementSelected[0][CONSTANT.PR_COL_SETTLEMENT_ID];
                viewDocumentButton = hasDocumentAttached(recordId, this.props.recordIdToDocumentIdMapping) ?
                    <Button disabled ={viewDocumentDisabled}
                            onClick={() =>this.props.handleQueryDocument()}>View Documents</Button> : "";
                updateWindow = <UpdatePaymentModal handleConfirmUpdateClick={this.props.handleConfirmUpdateClick}
                                                   updateWindowVisible={this.props.updateWindowVisible}
                                                   selectedPayment={selectedElement}
                                                   elementSelected={this.props.elementSelected}
                                                   handleDismissClick={this.props.handleDismissClick}
                                                   accountCategoryToRegionMap={this.props.accountCategoryToRegionMap}
                                                   companyCodeToKyribaBankAccountsMap={this.props.companyCodeToKyribaBankAccountsMap}
                                                   fxRequiringCurrencyMapping={this.props.fxRequiringCurrencyMapping}
                                                   weekendCalendarMapping={this.props.weekendCalendarMapping}
                                                   holidayCalendarMapping={this.props.holidayCalendarMapping}
                                                   currencyToDecimalDigitMapping={this.props.currencyToDecimalDigitMapping}
                                                   disableOverrideButton={this.props.disableOverrideButton}
                                                   user={this.props.user}/>;
            } else {
                batchUpdateDisabled = false;
                batchUpdateWindow = <BatchUpdatePaymentModal handleConfirmBatchUpdateClick={this.props.handleConfirmBatchUpdateClick}
                                                             batchUpdateWindowVisible={this.props.batchUpdateWindowVisible}
                                                             elementSelected={this.props.elementSelected}
                                                             handleDismissClick={this.props.handleDismissClick}
                                                             user={this.props.user}
                                                             rejectionDisabled={false}/>;
            }
        }

        //Comment window
        let commentModal = <CommentModal commentVisible={this.props.commentVisible}
                                         handleDismissClick={this.props.handleDismissClick}
                                         handleAddCommentClick={this.props.handleAddCommentClick}
                                         elementSelected={this.props.elementSelected}
                                         newComment={this.props.newComment}
                                         handleCommentUpdate={this.props.handleCommentUpdate}
                                         userComments={this.props.userComments}/>

        //Download Payment window
        let paymentDownloadModal =
            <DownloadPaymentModal downloadWindowVisible = {this.props.downloadWindowVisible}
                                  handleDismissClick={this.props.handleDismissClick}
                                  handleConfirmDownloadClick={this.props.handleConfirmDownloadClick}
                                  elementSelected={this.props.elementSelected}
                                  ifDownloadAll={this.props.ifDownloadAll}/>

        //Validate Payment Export Modal
        let validatePaymentExportModal =
            <ValidatePaymentExportModal validatePaymentExportModalVisible={this.props.validatePaymentExportModalVisible}
                                     handleConfirmValidateClick={this.props.handleConfirmValidateClick}
                                     missingBeneficiary={this.props.missingBeneficiary}
                                     ocmDuplicates={this.props.ocmDuplicates}
                                     exportPaymentType={this.props.exportPaymentType}
                                     paymentValidationStatus={this.props.paymentValidationStatus}
                                     handleDismissClick={this.props.handleDismissClick}/>

        //Construct the action Item bars for the dataContentTable
        let tableHeader = <></>;
        if (this.props.userGroup.includes(CONSTANT.USER_GROUP_TBO_PAYMENT_AUTHORIZORS) ||
        this.props.userGroup.includes(CONSTANT.USER_GROUP_INTERCOMPANY) ||
            this.props.userGroup.includes(CONSTANT.USER_GROUP_PROD_ADMIN) ||
            (this.props.userGroup.includes(CONSTANT.USER_GROUP_DEVELOPER) &&
                getStage() !== 'prod')) {
            tableHeader = <SpaceBetween direction="vertical" size="m">
                <>{this.props.feedbackMessage}</>
                <TextContent><h2>Pending Settlements</h2></TextContent>
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button disabled={singleUploadDisabled}
                                    onClick={() => this.props.handleSingleDocumentClick()}>Single Upload Document</Button>
                            <Button disabled={updateDisabled}
                                    onClick={() => this.props.handleViewUpdateWindowClick()}>Update/Reject</Button>
                            <Button disabled={batchUpdateDisabled}
                                    onClick={() => this.props.handleViewBatchUpdateWindowClick()}>Batch Update/Reject</Button>
                            <Button name="Comments"
                                    disabled={commentsDisabled}
                                    onClick={() => this.props.handleViewCommentClick()}>Add/View Comments</Button>
                            <ButtonDropdown
                                items={DOWNLOAD_SETTLEMENTS_DROPDOWN_ITEMS}
                                onItemClick={(event) => this.props.handleViewDownloadWindowClick(event.detail.id)}
                                disabled={downloadDisabled}>
                                Download
                            </ButtonDropdown>
                            {(this.props.userGroup.includes(CONSTANT.USER_GROUP_TBO_PAYMENT_AUTHORIZORS) ||
                            this.props.userGroup.includes(CONSTANT.USER_GROUP_PROD_ADMIN)) && <ButtonDropdown
                                items={AUTO_EXPORT_DROPDOWN_ITEMS}
                                onItemClick={(event) => this.props.handleFileGenerationClick(this.props.elementSelected, event.detail.id, CONSTANT.EXPORT_AUTO)}
                                disabled={fileGenerationDisabled}>
                                Export Payments
                            </ButtonDropdown>}
                            <ButtonDropdown
                                items={FILE_GENERATION_DROPDOWN_ITEMS}
                                onItemClick={(event) => this.props.handleFileGenerationClick(this.props.elementSelected, event.detail.id, CONSTANT.EXPORT_MANUAL)}
                                disabled={fileGenerationDisabled}>
                                Manual Export
                            </ButtonDropdown>
                            {viewDocumentButton}
                        </SpaceBetween>
            </SpaceBetween>;
        } else if (this.props.userGroup.includes(CONSTANT.USER_GROUP_CASH_POSITIONER)){
            tableHeader = <SpaceBetween direction="vertical" size="m">
                <>{this.props.feedbackMessage}</>
                <TextContent><h2>Pending Settlements</h2></TextContent>
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button disabled={singleUploadDisabled}
                                    onClick={() => this.props.handleSingleDocumentClick()}>Single Upload Document</Button>
                            <Button disabled={updateDisabled}
                                    onClick={() => this.props.handleViewUpdateWindowClick()}>Update/Reject</Button>
                            <Button disabled={batchUpdateDisabled}
                                    onClick={() => this.props.handleViewBatchUpdateWindowClick()}>Batch Update/Reject</Button>
                            <Button name="Comments"
                                    disabled={commentsDisabled}
                                    onClick={() => this.props.handleViewCommentClick()}>Add/View Comments</Button>
                            <ButtonDropdown
                                items={DOWNLOAD_SETTLEMENTS_DROPDOWN_ITEMS}
                                onItemClick={(event) => this.props.handleViewDownloadWindowClick(event.detail.id)}
                                disabled={downloadDisabled}>
                                Download
                            </ButtonDropdown>
                            {viewDocumentButton}
                        </SpaceBetween>
            </SpaceBetween>;
        } else if (this.props.userGroup.includes(CONSTANT.USER_GROUP_SETTLEMENT_HISTORICAL)){
            tableHeader = <SpaceBetween direction="vertical" size="m">
                <>{this.props.feedbackMessage}</>
                <TextContent><h2>Pending Settlements</h2></TextContent>
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button disabled={singleUploadDisabled}
                                    onClick={() => this.props.handleSingleDocumentClick()}>Single Upload Document</Button>
                            <Button name="Comments"
                                    disabled={commentsDisabled}
                                    onClick={() => this.props.handleViewCommentClick()}>Add/View Comments</Button>
                            <ButtonDropdown
                                items={DOWNLOAD_SETTLEMENTS_DROPDOWN_ITEMS}
                                onItemClick={(event) => this.props.handleViewDownloadWindowClick(event.detail.id)}
                                disabled={downloadDisabled}>
                                Download
                            </ButtonDropdown>
                            {viewDocumentButton}
                        </SpaceBetween>
            </SpaceBetween>;
        } else {
            tableHeader = <Header variant="h2">Pending Settlements</Header>;
        }

        return (
            <Form>
                <Container>
                    <div>
                        {commentModal}
                    </div>
                    <div>
                        {updateWindow}
                    </div>
                    <div>
                        {batchUpdateWindow}
                    </div>
                    <div>
                        {paymentDownloadModal}
                    </div>
                    <div>
                        {validatePaymentExportModal}
                    </div>
                    <div data-awsui-column-layout-root={true}>{
                        <DataContentTable distributions={
                                                (
                                                    this.props.companyCodeToKyribaBankAccountsMap &&
                                                    this.props.accountCategoryToRegionMap &&
                                                    this.props.fxRequiringCurrencyMapping &&
                                                    this.props.weekendCalendarMapping &&
                                                    this.props.holidayCalendarMapping
                                                ) ? this.props.distributions : []
                                          }
                                          onRowClick={this.props.onRowClick}
                                          contentSelectorOptions={this.props.contentSelectorOptions}
                                          filterOptions={this.props.filterOptions}
                                          columnDefinitions={this.props.columnDefinitions}
                                          header={tableHeader}
                                          sortableColumns={this.props.sortableColumns}
                                          elementSelected={this.props.elementSelected}
                                          onSelectionChange={this.props.onSelectionChange}/>
                    }</div>
                </Container>
            </Form>);
    }
}

/*
 *  QueryApprovedPayment Parent class that wraps the above UI container and routes the business logic handlers.
 */
class QueryApprovedPaymentData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: CONSTANT.MODAL_EMPTY,
            statusMsg: "",
            loading: false,
            distributions: [],
            companyCodeToKyribaBankAccountsMap: [],
            accountCategoryToRegionMap: [],
            bloombergAccountList: [],
            kyribaToMisysMapping: [],
            fxRequiringCurrencyMapping: [],
            weekendCalendarMapping: [],
            holidayCalendarMapping: [],
            currencyToDecimalDigitMapping: [],
            recordIdToDocumentIdMapping: {},
            recordIdToDocumentIdMappingLoaded: false,
            elementSelected: [],
            userGroup: localStorage.getItem(CONSTANT.USER_GROUP_NAME),
            user: localStorage.getItem(CONSTANT.LOCAL_STORAGE_USERID),
            newComment: '',
            commentsList: 0,
            userComments: {'userComment': [], 'commentId': ''},
            commentVisible: false,
            updateWindowVisible: false,
            batchUpdateWindowVisible: false,
            downloadWindowVisible: false,
            validatePaymentExportModalVisible: false,
            missingBeneficiary: [],
            ocmDuplicates: [],
            paymentValidationStatus: '',
            exportType: '',
            paymentExportType: '',
            ifDownloadAll: true,
            disableOverrideButton: true,
            documentData:[],
            documentVisible: false,
            singleUploadVisible: false,
            icLdapOverlap: arrayIntersection(IC_SETTLEMENTS_LDAP, localStorage.getItem(CONSTANT.USER_GROUP_NAME)),
            loadedMappings: 0,
        }
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleCommentUpdate = this.handleCommentUpdate.bind(this);
        this.onSelectionChange = this.onSelectionChange.bind(this);
    }

    /**
     * Function for row click in the data table.
     * The elementSelected is the row selected.
     */
    onRowClick = ({detail}) => {
        let newElementSelected = [...this.state.elementSelected];
        if (newElementSelected.includes(detail.item)) {
            newElementSelected = newElementSelected.filter(x => x !== detail.item)
        } else {
            newElementSelected.push(detail.item)
        }
        this.onSelectionChange({detail: {selectedItems: newElementSelected}});
    };

    componentDidMount() {
        Logger.logInfo('componentDidMount');

        /**
         * No processing is needed if the user is in more than one IC Settlements group, as the module
         * will simply return a Polaris Alert asking the user to remove themselves from all
         * but one IC settlement group before proceeding.
         */
        if (this.state.icLdapOverlap.length > 1) {
            return;
        }
        var parameter = {indexString: CONSTANT.INDEX_STRING_APPROVED_PAYMENT_REVIEW, userGroup: this.state.userGroup};
        this.setState({
            status: CONSTANT.MODAL_QUERYING
        });

        //Get paymentRecords of corresponding status for display
        APIClient.invoke('GET', 'payment', parameter,
            undefined,
            (err, data) => {
                this.setState({loading: false});
                if (!err) {
                    Logger.logInfo("Data reterived: " + JSON.stringify(data));
                    switch (data.status) {
                        case CONSTANT.RESPONSE_SUCCESS:

                            this.setState({
                                status: CONSTANT.MODAL_UPDATE_SUCCESS,
                                distributions: data.data
                            });
                            Logger.logInfo("Query Payment distributions state:" + JSON.stringify(this.state.distributions))
                            break;
                        case CONSTANT.RESPONSE_ERROR:
                            Logger.logError("Response Error when Querying data records ");
                            this.setState({
                                status: CONSTANT.MODAL_QUERY_ERROR,
                                distributions: data.data,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data));
                            break;
                        case CONSTANT.RESPONSE_SYSTEM_ERROR:
                            Logger.logError("System Error when Querying data records ");
                            this.setState({
                                status: CONSTANT.MODAL_SYSTEM_ERROR,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data['errorData']));
                            break;
                        case CONSTANT.MODAL_REQUEST_ERROR:
                            Logger.logError("Request Error when Querying data records ");
                            this.setState({
                                status: CONSTANT.MODAL_REQUEST_ERROR,
                                distributions: data.data,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data['errorData']));
                            break;
                        default:
                    }
                } else {
                    Logger.logError("Other Error when  Querying payment data records ");
                    if (this.state.status != CONSTANT.MODAL_OTHER_ERROR) {
                        this.setState({
                            status: CONSTANT.MODAL_OTHER_ERROR,
                            statusMsg: "Other System Error when Querying payment data records"
                        });
                    }
                }
            });

        // GET accountCategoryToRegionMapping
        var parameterGetSettlement = {indexString: CONSTANT.INDEX_STRING_REGION_MAPPING};

        APIClient.invoke('GET', 'payment', parameterGetSettlement,
            undefined,
            (err, data) => {
                if (!err) {
                    switch (data.status) {
                        case CONSTANT.RESPONSE_SUCCESS:
                            this.setState({
                                accountCategoryToRegionMap: data.data
                            });
                            Logger.logInfo("accountCategoryToRegionMap:" + JSON.stringify(this.state.accountCategoryToRegionMap))
                            break;
                        case CONSTANT.RESPONSE_ERROR:
                            Logger.logError("Response Error when Querying accountCategoryToRegionMap ");
                            this.setState({
                                status: CONSTANT.MODAL_QUERY_ERROR,
                                accountCategoryToRegionMap: data.data,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data));
                            break;
                        default:
                    }
                } else {
                    Logger.logError("Other Error when  Querying accountCategoryToRegionMap ");
                    if (this.state.status != CONSTANT.MODAL_OTHER_ERROR) {
                        this.setState({
                            status: CONSTANT.MODAL_OTHER_ERROR,
                            statusMsg: "Other System Error when Querying accountCategoryToRegionMap"
                        });
                    }
                }
            });

        // Get All Kyriba Bank Accounts Information as CompanyCode->KyribaBankAccountRecordsList Map
        var parameterGetKyribaBankAccount = {dataType: CONSTANT.QUERY_STRING_PARAM_TYPE_KYRIBA_BANK_ACCOUNT};
        APIClient.invoke('GET', 'queryKyribaData', parameterGetKyribaBankAccount,
            undefined,
            (err, data) => {
                this.setState({loading: false});
                if (!err) {
                    switch (data.status) {
                        case CONSTANT.RESPONSE_SUCCESS:
                            this.setState({
                                companyCodeToKyribaBankAccountsMap: data.data
                            });
                            Logger.logInfo("companyCodeToKyribaBankAccountsMap state:" + JSON.stringify(this.state.companyCodeToKyribaBankAccountsMap))
                            break;
                        case CONSTANT.RESPONSE_ERROR:
                            Logger.logError("Not able to query kyribaBankAccount information ");
                            this.setState({
                                status: CONSTANT.MODAL_QUERY_ERROR,
                                companyCodeToKyribaBankAccountsMap: data.data,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data));
                            break;
                        default:
                    }
                } else {
                    Logger.logError("Other Error when QueryingkyribaBankAccount information");
                    if (this.state.status != CONSTANT.MODAL_OTHER_ERROR) {
                        this.setState({
                            status: CONSTANT.MODAL_OTHER_ERROR,
                            statusMsg: "Other System Error when Querying kyribaBankAccount information"
                        });
                    }
                }
            });

        // Get Kyriba Currency to Decimal Digit Mapping 
        var parameterGetKyribaCurrency = {dataType: CONSTANT.QUERY_STRING_PARAM_TYPE_KYRIBA_CURRENCY};
        APIClient.invoke('GET', 'queryKyribaData', parameterGetKyribaCurrency,
            undefined,
            (err, data) => {
                this.setState({loading: false});
                if (!err) {
                    switch (data.status) {
                        case CONSTANT.RESPONSE_SUCCESS:
                            this.setState({
                                currencyToDecimalDigitMapping: data.data
                            });
                            Logger.logInfo("currencyToDecimalDigitMapping state:" + JSON.stringify(this.state.currencyToDecimalDigitMapping))
                            break;
                        case CONSTANT.RESPONSE_SYSTEM_ERROR:
                            Logger.logError("Not able to query kyribaCurrency information");
                            this.setState({
                                status: CONSTANT.MODAL_QUERY_ERROR,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data));
                            break;
                        default:
                    }
                } else {
                    Logger.logError("Other error occurred when querying for kyribaCurrency information: " + JSON.stringify(err));
                    if (this.state.status != CONSTANT.MODAL_OTHER_ERROR) {
                        this.setState({
                            status: CONSTANT.MODAL_OTHER_ERROR,
                            statusMsg: "Other error occurred when querying for kyribaCurrency information"
                        });
                    }
                }
            });

        //Get FxRequiringCurrencyMapping
        var parameterGetFxRequiringCurrencyMapping = {dataType:
            CONSTANT.QUERY_STRING_PARAM_TYPE_GENERAL_TRS_FX_REQUIRING_CURRENCY};

        APIClient.invoke('GET', 'generalTRSData', parameterGetFxRequiringCurrencyMapping,
            undefined,
            (err, data) => {
                this.setState({loading: false});
                if (!err) {
                    switch (data.status) {
                        case CONSTANT.RESPONSE_SUCCESS:
                            this.setState({
                                fxRequiringCurrencyMapping: data.data
                            });
                            Logger.logInfo("fxRequiringCurrencyMapping state:" + JSON.stringify(this.state.fxRequiringCurrencyMapping))
                            break;
                        case CONSTANT.RESPONSE_ERROR:
                            Logger.logError("Not able to query FXRequiringCurrency information ");
                            this.setState({
                                status: CONSTANT.MODAL_QUERY_ERROR,
                                fxRequiringCurrencyMapping: data.data,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data));
                            break;
                        default:
                    }
                } else {
                    Logger.logError("Other Error when Querying FXRequiringCurrency information");
                    if (this.state.status != CONSTANT.MODAL_OTHER_ERROR) {
                        this.setState({
                            status: CONSTANT.MODAL_OTHER_ERROR,
                            statusMsg: "Other System Error when Querying FXRequiringCurrency information"
                        });
                    }
                }
            });

        //Get WeekendCalendarMapping
        var parameterGetWeekendCalendarMapping = {dataType:
            CONSTANT.QUERY_STRING_PARAM_TYPE_GENERAL_TRS_FX_WEEKEND_CALENDAR};

        APIClient.invoke('GET', 'generalTRSData', parameterGetWeekendCalendarMapping,
            undefined,
            (err, data) => {
                this.setState({loading: false});
                if (!err) {
                    switch (data.status) {
                        case CONSTANT.RESPONSE_SUCCESS:
                            this.setState({
                                weekendCalendarMapping: data.data
                            });
                            Logger.logInfo("weekendCalendarMapping state:" + JSON.stringify(this.state.weekendCalendarMapping))
                            break;
                        case CONSTANT.RESPONSE_ERROR:
                            Logger.logError("Not able to query weekendCalendarMapping information ");
                            this.setState({
                                status: CONSTANT.MODAL_QUERY_ERROR,
                                weekendCalendarMapping: data.data,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data));
                            break;
                        default:
                    }
                } else {
                    Logger.logError("Other Error when Querying weekendCalendarMapping information");
                    if (this.state.status != CONSTANT.MODAL_OTHER_ERROR) {
                        this.setState({
                            status: CONSTANT.MODAL_OTHER_ERROR,
                            statusMsg: "Other System Error when Querying weekendCalendarMapping information"
                        });
                    }
                }
            });

        //Get HolidayCalendarMapping
        var parameterGetHolidayCalendarMapping = {dataType:
            CONSTANT.QUERY_STRING_PARAM_TYPE_GENERAL_TRS_FX_HOLIDAY_CALENDAR};

        APIClient.invoke('GET', 'generalTRSData', parameterGetHolidayCalendarMapping,
            undefined,
            (err, data) => {
                this.setState({loading: false});
                if (!err) {
                    switch (data.status) {
                        case CONSTANT.RESPONSE_SUCCESS:
                            this.setState({
                                holidayCalendarMapping: data.data
                            });
                            Logger.logInfo("holidayCalendarMapping state:" + JSON.stringify(this.state.holidayCalendarMapping))
                            break;
                        case CONSTANT.RESPONSE_ERROR:
                            Logger.logError("Not able to query holidayCalendarMapping information ");
                            this.setState({
                                status: CONSTANT.MODAL_QUERY_ERROR,
                                holidayCalendarMapping: data.data,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data));
                            break;
                        default:
                    }
                } else {
                    Logger.logError("Other Error when Querying holidayCalendarMapping information");
                    if (this.state.status != CONSTANT.MODAL_OTHER_ERROR) {
                        this.setState({
                            status: CONSTANT.MODAL_OTHER_ERROR,
                            statusMsg: "Other System Error when Querying holidayCalendarMapping information"
                        });
                    }
                }
            });

        //Get Bloomberg Account List
        var parameterBloombergAccounts = {dataType:
            CONSTANT.QUERY_STRING_PARAM_TYPE_GENERAL_TRS_BLOOMBERG_ACCOUNTS};

        APIClient.invoke('GET', 'generalTRSData', parameterBloombergAccounts,
            undefined,
            (err, data) => {
                this.setState({loading: false});
                if (!err) {
                    switch (data.status) {
                        case CONSTANT.RESPONSE_SUCCESS:
                            this.setState({
                                bloombergAccountList: data.data.BloombergAccounts
                            });
                            Logger.logInfo("bloombergAccountList state:" + JSON.stringify(this.state.bloombergAccountList))
                            break;
                        case CONSTANT.RESPONSE_ERROR:
                            Logger.logError("Not able to query Bloomberg Account list information ");
                            this.setState({
                                status: CONSTANT.MODAL_QUERY_ERROR,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data));
                            break;
                        default:
                    }
                } else {
                    Logger.logError("Other error occurred when querying Bloomberg Account list information");
                    if (this.state.status != CONSTANT.MODAL_OTHER_ERROR) {
                        this.setState({
                            status: CONSTANT.MODAL_OTHER_ERROR,
                            statusMsg: "Other System Error occurred when querying Bloomberg Account list information"
                        });
                    }
                }
            });

        //Get IC Misys Template Mapping
        var parameterGetKyribaMisysMapping = {dataType: CONSTANT.QUERY_STRING_PARAM_TYPE_KYRIBA_MISYS_MAPPING};

        APIClient.invoke('GET', 'queryKyribaData', parameterGetKyribaMisysMapping,
            undefined,
            (err, data) => {
                this.setState({loading: false});
                if (!err) {
                    switch (data.status) {
                        case CONSTANT.RESPONSE_SUCCESS:
                            this.setState({
                                kyribaToMisysMapping: data.data
                            });
                            Logger.logInfo("kyribaToMisysMapping state:" + JSON.stringify(this.state.kyribaToMisysMapping))
                            break;
                        case CONSTANT.RESPONSE_ERROR:
                            Logger.logError("Not able to query kyribaToMisysMapping information ");
                            this.setState({
                                status: CONSTANT.MODAL_QUERY_ERROR,
                                kyribaToMisysMapping: data.data,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data));
                            break;
                        default:
                    }
                } else {
                    Logger.logError("Other Error when kyribaToMisysMapping information");
                    if (this.state.status != CONSTANT.MODAL_OTHER_ERROR) {
                        this.setState({
                            status: CONSTANT.MODAL_OTHER_ERROR,
                            statusMsg: "Other System Error when Querying kyribaToMisysMapping information"
                        });
                    }
                }
            });

        //Query RecordIdToDocumentIdMapping
        const paramGetRecordIdToDocumentIdMapping = {dataType:
            CONSTANT.QUERY_STRING_PARAM_TYPE_DOCUMENT_ID_MAPPING};

        APIClient.invoke('GET', '/attachDocuments/documentData', paramGetRecordIdToDocumentIdMapping,
            undefined,
            (err, data) => {
                this.setState({loading: false});
                if (!err) {
                    this.setState({
                        recordIdToDocumentIdMapping: data,
                        recordIdToDocumentIdMappingLoaded: true,
                    });
                    Logger.logInfo("recordIdToDocumentIdMaping state:" + JSON.stringify(this.state.recordIdToDocumentIdMapping))
                } else {
                    Logger.logError("Other Error when Querying recordIdToDocumentIdMapping information");
                    if (this.state.status != CONSTANT.MODAL_OTHER_ERROR) {
                        this.setState({
                            status: CONSTANT.MODAL_OTHER_ERROR,
                            statusMsg: "Other System Error when Querying recordIdToDocumentIdMapping information"
                        });
                    }
                }
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.status != this.state.status) {
            // If file generation has successfully completed, update the elements seletected to "Export" status
            if (this.state.status === CONSTANT.MODAL_FILE_GENERATION_SUCCESS) {
                this.handleUpdate(this.state.elementSelected, "Export");
            }
        }
    }

    // Handler for selection change in the data table
    onSelectionChange({detail}) {
        Logger.logInfo("onSelectionChange: " + JSON.stringify(detail));
        this.setState(() => {
            Logger.logInfo("Start: ");
            let commentObject = {};

            if (detail.selectedItems.length === 1) {
                detail.selectedItems.forEach((item) => {
                    commentObject["commentId"] = item[CONSTANT.TR_COL_COMMENT_ID];
                    commentObject["userComment"] = item[CONSTANT.PR_COL_USER_COMMENTS] ? JSON.parse(item[CONSTANT.PR_COL_USER_COMMENTS]) : [];
                });

                return {
                    newComment: '',
                    commentsList: 1,
                    userComments: commentObject,
                    elementSelected: detail.selectedItems
                };
            } else {
                return {
                    newComment: '',
                    commentsList: detail.selectedItems.length,
                    userComments: {'userComment': [], 'commentId': ''},
                    elementSelected: detail.selectedItems
                };
            }
        });
        Logger.logInfo("State commentsList: " + JSON.stringify(this.state.commentsList));
    }

    // Handle view Comment Click
    handleViewCommentClick = () => {
        Logger.logInfo("handleViewCommentClick: ");
        this.setState({commentVisible: true})
        Logger.logInfo("commentVisible: " + this.state.commentVisible);
    }

    // Handle Comment Update
    handleCommentUpdate = (event) => {
        Logger.logInfo("Comment event: " + JSON.stringify(event));
        this.setState({
            newComment: event.detail.value
        });
    }

    // Handle add comment in the comment dialog
    handleAddCommentClick = (elementSelected, newComment) => {
        Logger.logInfo("handleAddCommentClick: " + newComment);

        if (newComment) {
            let commentDate = FormatData.formateNewUTCCurrentTime();

            //Add the new comments to userComments field of the selected elements.
            elementSelected.map((entry, index) => {
                // The comment field is stored as serialized string, deserialize the string to get the userComments Object.
                let userCommentsList = elementSelected[index][CONSTANT.PR_COL_USER_COMMENTS] ?
                    JSON.parse(elementSelected[index][CONSTANT.PR_COL_USER_COMMENTS]) : [];

                let newCommentObject = {};
                newCommentObject["Comment"] = newComment;
                newCommentObject["User"] = this.state.user;
                newCommentObject["CommentDate"] = commentDate;
                userCommentsList.push(newCommentObject);

                elementSelected[index][CONSTANT.PR_COL_USER_COMMENTS] = JSON.stringify(userCommentsList);
            })

            // Invoke update handler to call the update API endpoints.
            this.handleUpdate(elementSelected, 'AddComments');
            this.setState({commentVisible: false});
        } else {
            Logger.logInfo("No Comment: ");
            this.setState({commentVisible: true});
        }
    }

    // Handle View UpdateWindow Click
    handleViewUpdateWindowClick = () => {
        Logger.logInfo("handleViewUpdateWindowClick: ");
        this.setState({updateWindowVisible: true})
        Logger.logInfo("updateWindowVisible: " + this.state.updateWindowVisible);
    }

    handleViewBatchUpdateWindowClick = () => {
        Logger.logInfo("handleViewBatchUpdateWindowClick: ");
        this.setState({batchUpdateWindowVisible: true})
        Logger.logInfo("batchUpdateWindowVisible: " + this.state.batchUpdateWindowVisible);
    }

    // Handle ConfirmUpdate  Click
    handleConfirmUpdateClick = (elementSelected) => {
        Logger.logInfo("handleConfirmUpdateClick: ");
        this.handleUpdate(elementSelected, "Update")
        this.setState({updateWindowVisible: false});
    }

    // Handle ConfirmUpdate  Click
    handleConfirmBatchUpdateClick = (elementSelected) => {
        Logger.logInfo("handleConfirmBatchUpdateClick: ");
        this.handleUpdate(elementSelected, "Update")
        this.setState({batchUpdateWindowVisible: false});
    }

    // Handle confirm validate click for File Export
    handleConfirmValidateClick = () => {
        Logger.logInfo("handleConfirmValidateClick: ");
        this.setState({validatePaymentExportModalVisible: false});

        // If the user confirms file export, begin executing file generation logic
        this.startFileGeneration(this.state.exportPaymentType, this.state.elementSelected, this.state.exportType)
    }

    // Handle view downloadWindow click
    handleViewDownloadWindowClick = (button_id) => {
        Logger.logInfo("handleViewDownloadWindowClick: ");
        let ifDownloadAll = button_id === 'download_all' ? true : false;
        this.setState({
            downloadWindowVisible: true,
            ifDownloadAll: ifDownloadAll
        })
        Logger.logInfo("downloadWindowVisible: " + this.state.downloadWindowVisible);
    }

    // Handle confirm download click
    handleConfirmDownloadClick = (fileType, ifDownloadAll) => {
        Logger.logInfo("handleDownloadClick fileType: " + fileType);
        let fileName = "TRS_Settlements_Download" + FormatData.formatNewFileDate() + '.' + fileType;
        let delimiter = DOWNLOAD_FILE_PAYMENT_DELIMITER;
        let header = VISIBLE_COLUMN_OPTIONS_APPROVED_PAYMENT;
        let enrichedPayments;
        if (ifDownloadAll) {
            enrichedPayments = this.enrichPaymentsForDownload(this.state.distributions);
        } else {
            enrichedPayments = this.enrichPaymentsForDownload(this.state.elementSelected);
        }
        if (fileType === 'xlsx') {
            xlsxParser.writeFile(fileName, delimiter, enrichedPayments, header, fileType);
        } else if (fileType === 'csv') {
            xlsxParser.writeFileANSI(fileName, delimiter, enrichedPayments, header);
        }

        // Dismiss the download window
        this.setState({downloadWindowVisible: false})
    }

    // queryDocuments for the queryDocumentData page.
    handleQueryDocument = () => {
        let id = "";
        this.setState({
            status: CONSTANT.MODAL_QUERYING
        })
        this.state.elementSelected.map(item => {
            id = item[CONSTANT.PR_COL_SETTLEMENT_ID]
        })
        Logger.logInfo("handleQueryDocument: " + JSON.stringify(id));
        const parameter = {
            id: id,
        };

        APIClient.invoke('GET', 'attachDocuments/viewDocument', parameter, this.state.elementSelected, (err, requestData) => {
            Logger.logInfo("Response data: " + JSON.stringify(requestData));
            if (!err) {
                switch (requestData.status) {
                    case CONSTANT.RESPONSE_SUCCESS:
                        this.setState({
                            documentData: requestData.data,
                            documentVisible: true,
                            status: CONSTANT.MODAL_QUERY_SUCCESS
                        });
                        Logger.logInfo("Query Document Data succeed.");

                        break;
                    case CONSTANT.RESPONSE_ERROR:
                        Logger.logError("Error Query Document Data = " + this.state.elementSelected.toString());
                        this.setState({
                            documentVisible: false,
                            status: CONSTANT.MODAL_QUERY_ERROR
                        });
                        break;
                    case CONSTANT.RESPONSE_SYSTEM_ERROR:
                        Logger.logError("System Error when Query Document Data = " + this.state.elementSelected.toString());
                        this.setState({
                            status: CONSTANT.MODAL_SYSTEM_ERROR,
                            statusMsg: JSON.stringify(requestData['errorData'])
                        });
                        break;
                    default:
                }
            } else {
                Logger.logError("Other System Error when Query Document Data = " + this.state.elementSelected.toString());
                this.setState({
                    status: CONSTANT.MODAL_OTHER_ERROR,
                    statusMsg: "Other System Error when query document data records."
                });
            }
        })
    }

    /*  Enrich Payment for Download
        Since some fields displayed the table are not contained in the elementSelected/distribution object
        and are retrieved elsewhere instead,
        this function enrich those fields so that all the columnds displayed in the table can be downloaded. */
    enrichPaymentsForDownload = (elementSelected) => {
        // Prepare a deep clone of elementSelected.
        let elementSelectedCopy = JSON.parse(JSON.stringify(elementSelected))
        let enrichedPayments =  elementSelectedCopy.map(payment => {
            // Enrich some fields of each of the payment element.
            VISIBLE_COLUMN_OPTIONS_APPROVED_PAYMENT.forEach((column) => {
                if (column === CONSTANT.PR_COL_RECEIVING_AMOUNT ||
                    column === CONSTANT.PR_COL_SENDING_AMOUNT ||
                    column === CONSTANT.PR_COL_SETTLEMENT_USD_EQUIV ||
                    column === CONSTANT.PR_COL_TARGET_AMOUNT) {
                    //pass
                } else if (column === CONSTANT.PR_COL_UPLOAD_DATE ||
                    column === CONSTANT.PR_COL_CREATION_DATE ||
                    column === CONSTANT.PR_COL_UPDATE_DATE ||
                    column === CONSTANT.PR_COL_APPROVAL_DATE ||
                    column === CONSTANT.PR_COL_REJECTION_DATE ||
                    column === CONSTANT.PR_COL_EXPORT_DATE){
                    //Format UTC ISO DateString (Used in DDB) to local timezone DateString
                    payment[column] = FormatData.formatISODateToPSTTimezoneDate(payment[column]);
                } else if (column === 'Latest Comment') {
                    payment[column] = getLatestComment(payment);
                } else if (column === 'Sending Region' || column === 'Receiving Region') {
                    payment[column] = populateRegion(payment, column);
                } else if (column === 'Sending Branch') {
                    payment[column] = lookupBranch(payment[CONSTANT.PR_COL_SENDING_ACCOUNT],
                        payment[CONSTANT.PR_COL_SENDING_CO], this.state.companyCodeToKyribaBankAccountsMap);
                } else if (column === 'Receiving Branch') {
                    payment[column] = lookupBranch(payment[CONSTANT.PR_COL_RECEIVING_ACCOUNT],
                        payment[CONSTANT.PR_COL_RECEIVING_CO], this.state.companyCodeToKyribaBankAccountsMap);
                } else if (column === 'File Attached'){
                    payment[column] = hasDocumentAttached(payment[CONSTANT.PR_COL_SETTLEMENT_ID],
                        this.state.recordIdToDocumentIdMapping) ? 'Yes' : 'No';
                }
            });
            // If the payment element contain a key that's not in VISIBLE_COLUMN_OPTIONS_OPEN_PAYMENT, remove the value.
            for (const key in payment) {
                if (!VISIBLE_COLUMN_OPTIONS_APPROVED_PAYMENT.includes(key)) {
                    delete payment[key];
                }
            }
            return payment;
        })
        return enrichedPayments;
    }

    // Handle Dismiss or Cancel for modals' window
    handleDismissClick = () => {
        Logger.logInfo("handleDismissClick Start: ");
        if (this.state.commentVisible) {
            this.setState({commentVisible: false})
        } else if (this.state.updateWindowVisible) {
            this.setState({updateWindowVisible: false})
        } else if (this.state.batchUpdateWindowVisible){
            this.setState({batchUpdateWindowVisible: false})
        } else if (this.state.downloadWindowVisible) {
            this.setState({downloadWindowVisible: false})
        } else if (this.state.validatePaymentExportModalVisible) {
            this.setState({validatePaymentExportModalVisible: false})
        }
    }

    getBeneficiary(payment) {
        let beneficiary = '';
        // Use Recieving Account to find mapping in Misys ID table
        if (Object.keys(this.state.kyribaToMisysMapping).length !== 0 && payment[CONSTANT.PR_COL_RECEIVING_ACCOUNT]) {
            beneficiary = this.state.kyribaToMisysMapping[payment[CONSTANT.PR_COL_RECEIVING_ACCOUNT]];
        }
        // If Misys ID table does not include mapping, check Bloomberg list
        if (!beneficiary && this.state.bloombergAccountList.includes(payment[CONSTANT.PR_COL_RECEIVING_ACCOUNT])) {
            beneficiary = payment[CONSTANT.PR_COL_RECEIVING_ACCOUNT];
        }
        return beneficiary;
    }

    handleDismissDocumentModalClick = () => {
        this.setState({
            documentVisible: false,
        })
    }

    handleDismissSingleUpload = () => {
        this.setState({
            singleUploadVisible: false,
        })
    }

    handleSingleDocumentClick = () => {
        this.setState({
            singleUploadVisible: true
        })
    }

    handleFileGenerationClick = (elementSelected, paymentType, uploadType) => {
        //Sanity Check: Payment Types must match
        if (!PaymentGeneration.validatePaymentType(elementSelected, paymentType)) {
            this.setState({
                statusMsg: "Unable to generate the payment file because: One or more payment selected are not " + paymentType + " payment type",
                status: CONSTANT.MODAL_OTHER_ERROR
            });
            return;
        }

        // Sanity Check: kyribaToMisysMapping must be populated for FX file generation
        if (paymentType === "FX" && Object.keys(this.state.kyribaToMisysMapping).length === 0) {
            this.setState({
                statusMsg: "Unable to generate the payment file because: Failed to get kyribaToMisysMapping",
                status: CONSTANT.MODAL_OTHER_ERROR
            });
            return;
        }

        // Sanity Check: companyCodeToKyribaBankAccountsMap must be populated for Wire file generation
        if (paymentType === "Wire" && Object.keys(this.state.companyCodeToKyribaBankAccountsMap).length === 0) {
            this.setState({
                statusMsg: "Unable to generate the payment file because: Failed to get Company Code to Kyriba Account mapping",
                status: CONSTANT.MODAL_OTHER_ERROR
            });
        }

        // After passing sanity checks, set state for business-required warning checks.
        this.setState({
            exportPaymentType: paymentType,
            exportType: uploadType,
        })

        this.executeExportValidationChecks(elementSelected, paymentType);
    }

    executeExportValidationChecks(elementSelected, paymentType) {
        // Execute Missing Beneficiary Check
        if (paymentType === "FX") {
            let missingBeneficiary = [];
            let elementSelectedCopy = JSON.parse(JSON.stringify(elementSelected));
            elementSelectedCopy.map(payment => {
                if (!this.getBeneficiary(payment)) {
                    missingBeneficiary.push(payment);
                }
            });
            this.setState({missingBeneficiary: missingBeneficiary})
        }

        // Set modal visibility to true, after missing beneficiary check is complete
        this.setState({
            validatePaymentExportModalVisible: true,
            paymentValidationStatus: CONSTANT.MODAL_PAYMENT_VALIDATE_START,
        });

        // Execute OCM Check
        this.executeOCMCheck(elementSelected);
    }

    /*
        Handle OCM Check
        Selected element will be sent to backend for validating if each payment has a duplicate relationship
        The validate warning modal will be visible to check all the warnings
    */
    executeOCMCheck = (elementSelected) => {
        let validatePayments = [];
        elementSelected.map(payment => {
            let validatePaymentItem = {};
            validatePaymentItem[CONSTANT.PR_COL_OCM_MAPPING] = payment[CONSTANT.PR_COL_OCM_MAPPING];
            validatePaymentItem[CONSTANT.PR_COL_SETTLEMENT_ID] = payment[CONSTANT.PR_COL_SETTLEMENT_ID];
            validatePaymentItem[CONSTANT.PR_COL_SETTLEMENT_CYCLE] = payment[CONSTANT.PR_COL_SETTLEMENT_CYCLE];
            validatePayments.push(validatePaymentItem);
        });

        const parameter = {
            indexString: CONSTANT.INDEX_STRING_APPROVED_PAYMENTS_DUPLICATE_RELATIONSHIP,
            userGroup: this.state.userGroup
        };
        
        APIClient.invoke('POST', 'payment', parameter, elementSelected, (err, requestData) => {
            Logger.logInfo("Response data: " + JSON.stringify(requestData));
            if (!err) {
                switch (requestData.status) {
                    case CONSTANT.RESPONSE_SUCCESS:
                        this.setState({
                            paymentValidationStatus: CONSTANT.MODAL_PAYMENT_VALIDATE_FINISH,
                            ocmDuplicates: requestData.data
                        });
                        Logger.logInfo("Payment duplicate relationship check finish");
                        break;
                    case CONSTANT.RESPONSE_ERROR:
                        Logger.logError("Error finding duplicate relationship with payments list = " + elementSelected.toString());
                        this.setState({
                            paymentValidationStatus: CONSTANT.MODAL_PAYMENT_VALIDATE_FAIL
                        });
                        break;
                    case CONSTANT.RESPONSE_SYSTEM_ERROR:
                        Logger.logError("System Error when finding duplicate relationship records with payments list = " + elementSelected.toString());
                        this.setState({
                            status: CONSTANT.MODAL_SYSTEM_ERROR,
                            statusMsg: JSON.stringify(requestData['errorData'])
                        });
                        break;
                    case CONSTANT.MODAL_REQUEST_ERROR:
                        this.setState({
                            status: CONSTANT.MODAL_REQUEST_ERROR,
                            statusMsg: JSON.stringify(requestData['errorData'])
                        });
                        break;
                    default:
                }
            } else {
                Logger.logError("Other System Error when finding duplicate relationship records with payments list = " + this.props.elementSelected.toString());
                this.setState({
                    status: CONSTANT.MODAL_OTHER_ERROR,
                    statusMsg: "Other System Error when finding duplicate relationship data records"
                });
            }
        })
    }

    startFileGeneration = (paymentType, elementSelected, uploadType) => {

        if (paymentType === "FX") {
            return this.startFXFileGeneration(elementSelected, uploadType);
        } else if (paymentType === "Wire"){
            return this.startWireFileGeneration(elementSelected, uploadType);
        } else {
            this.setState({
                statusMsg: "Unable to generate the payment file because: One or more payment selected are not " + paymentType + " payment type",
                status: CONSTANT.MODAL_OTHER_ERROR
            });
        }
    }

    startFXFileGeneration = (elementSelected, uploadType) => {
        // Iterate through user-selected IC Payments, to properly format file rows for export.
        let fxFileObject = [];
        let paymentCount = 0;
        let elementSelectedCopy = JSON.parse(JSON.stringify(elementSelected));
        elementSelectedCopy.map(payment => {
            let fxFileElement = {};
            let ttId = '';
            if (payment[CONSTANT.PR_COL_TT_ID]) {
                ttId = payment[CONSTANT.PR_COL_TT_ID].includes("_INTC") ? payment[CONSTANT.PR_COL_TT_ID] :
                    payment[CONSTANT.PR_COL_TT_ID] + "_INTC";
            }
            fxFileElement[CONSTANT.PR_FX_COL_TT_ID] = ttId;
            fxFileElement[CONSTANT.PR_FX_COL_TRADE_ID] = payment[CONSTANT.PR_COL_SETTLEMENT_ID];
            fxFileElement[CONSTANT.PR_FX_COL_VALUE_DATE] = payment[CONSTANT.PR_COL_VALUE_DATE];
            fxFileElement[CONSTANT.PR_FX_COL_SOURCE_ACCOUNT] = payment[CONSTANT.PR_COL_SENDING_ACCOUNT];
            fxFileElement[CONSTANT.PR_FX_COL_NOTIONAL_CCY] = payment[CONSTANT.PR_COL_TARGET_CURRENCY];
            fxFileElement[CONSTANT.PR_FX_COL_NOTIONAL] = payment[CONSTANT.PR_COL_TARGET_AMOUNT].toString();
            fxFileElement[CONSTANT.PR_FX_COL_TRADE_CODE] = payment[CONSTANT.PR_COL_OCM_MAPPING];
            fxFileElement[CONSTANT.PR_FX_COL_TRADE_DETAILS] = '';

            let beneficiary = this.getBeneficiary(payment); 
            if (!beneficiary) {
                // If Beneficiary is blank, push it to the missingBeneficiary list
                payment["row"] = paymentCount + 2; // Accounts for file header & 1-indexing
            }
            fxFileElement[CONSTANT.PR_FX_COL_BENEFICIARY] = beneficiary;

            fxFileObject.push(fxFileElement);
            paymentCount++;
        });

        // Get Dates for File Name
        let currDate = FormatData.formatNewCurrDate();
        let fileDate = FormatData.formatNewFileDate();
        let year_YYYY = currDate.getFullYear().toString();
        let month = currDate.getMonth() + 1;
        if (month < 10) {
            month = '0' + month;
        }
        // Generate File Name & Export Metadata
        let fileName = "TRS FX Upload_" + month + "_" + year_YYYY + "_export date_" + fileDate + ".xls";
        let header = FX_FILE_PAYMENT_HEADER;
        let delimiter = FX_FILE_PAYMENT_DELIMITER;
        let bookType = FX_FILE_PAYMENT_BOOKTYPE;

        // Execute file export logic. 
        //      For AUTO, drop a CSV file to FOCUS Intake Bucket.
        //      For MANUAL, download an XLSX file to the users machine.
        if (uploadType === CONSTANT.EXPORT_AUTO) {
            const csvString = xlsxParser.createCSV(fileName, delimiter, fxFileObject, header, bookType);
            const objectKey = ['InterCompany Trade', 'SYSTEM', localStorage.getItem(CONSTANT.LOCAL_STORAGE_USERID), fileName].join('/');
            const bucket = config.focusIntakeBucket[getStage()];
            S3Logic.stageFile(objectKey, bucket, csvString).then((response) => {
                if (response.status === CONSTANT.FEEDBACK_STAGING_SUCCESS) {
                    this.setState({
                        status: CONSTANT.MODAL_FILE_GENERATION_SUCCESS,
                        statusMsg: "Payments have been automatically exported for processing." 
                    });
                } else {
                    this.setState({
                        status: response.status,
                        statusMsg: "Automatic Export Issue Occurred: " + response.errorMessage 
                    });
                }
            });
        } else {
            xlsxParser.writeFile(fileName, delimiter, fxFileObject, header, bookType);
            this.setState({
                status: CONSTANT.MODAL_FILE_GENERATION_SUCCESS,
                statusMsg: "Payment file has been downloaded to your device for manual upload." 
            });
        }
    }

    startWireFileGeneration = (elementSelected, uploadType) => {
        // Get Dates for File Generation
        let currDate = FormatData.formatNewCurrDate();
        let fileDate = FormatData.formatNewFileDate();
        let year_YYYY = currDate.getFullYear().toString();
        let year_YY = year_YYYY.substr(2,2);
        let month = currDate.getMonth() + 1;
        if (month < 10) {
            month = '0' + month;
        }

        // Set-up objects for file generation
        let wireFileObject = [];
        let wireILSFileObject = [];
        wireFileObject.push(CONSTANT.PR_WIRE_FIXED_ROW2);
        wireILSFileObject.push(CONSTANT.PR_WIRE_FIXED_ROW2);

        // Iterate through user-selected IC Payments, to properly format file rows for export.
        let elementSelectedCopy = JSON.parse(JSON.stringify(elementSelected));
        elementSelectedCopy.map(payment => {
            let wireFileElement = {};
            wireFileElement[CONSTANT.PR_WIRE_COL_1] = "C";
            wireFileElement[CONSTANT.PR_WIRE_COL_2] = FormatData.formatWireFileValueDate(payment[CONSTANT.PR_COL_VALUE_DATE]);

            //Kyriba Transaction Code Logic
            let kyribaTransactionCode = this.getKyribaTransactionCode(payment);
            wireFileElement[CONSTANT.PR_WIRE_COL_3] = kyribaTransactionCode;
            wireFileElement[CONSTANT.PR_WIRE_COL_4] = payment[CONSTANT.PR_COL_SENDING_ACCOUNT];
            wireFileElement[CONSTANT.PR_WIRE_COL_5] = payment[CONSTANT.PR_COL_RECEIVING_ACCOUNT];
            wireFileElement[CONSTANT.PR_WIRE_COL_6] = payment[CONSTANT.PR_COL_TARGET_AMOUNT].toString();
            wireFileElement[CONSTANT.PR_WIRE_COL_7] = payment[CONSTANT.PR_COL_TARGET_CURRENCY];  
            wireFileElement[CONSTANT.PR_WIRE_COL_8] = payment[CONSTANT.PR_COL_OCM_MAPPING]; 

            //MMYY + File Type Column
            wireFileElement[CONSTANT.PR_WIRE_COL_9] = month + year_YY + " " + payment["File Type"];
            wireFileElement[CONSTANT.PR_WIRE_COL_10] = payment[CONSTANT.PR_COL_SETTLEMENT_ID];

            //RON SETTLEMENTS, remove settlement id
            if ((payment[CONSTANT.PR_COL_SENDING_CURRENCY] === 'RON' || payment[CONSTANT.PR_COL_RECEIVING_CURRENCY] === 'RON')
                && (this.checkIfBankIsRONCB(payment[CONSTANT.PR_COL_SENDING_ACCOUNT]) || 
                this.checkIfBankIsRONCB(payment[CONSTANT.PR_COL_RECEIVING_ACCOUNT]))) {
                    wireFileElement[CONSTANT.PR_WIRE_COL_10] = '';
            }

            //HSBC KRW POP Code - 99999
            if ((payment[CONSTANT.PR_COL_TARGET_CURRENCY] === 'KRW') &&
            (this.checkIfBankIsHSBC(payment[CONSTANT.PR_COL_SENDING_ACCOUNT]) || 
            this.checkIfBankIsHSBC(payment[CONSTANT.PR_COL_RECEIVING_ACCOUNT]))) {
                wireFileElement[CONSTANT.PR_WIRE_COL_14] = "99999";
            }

            if (kyribaTransactionCode === CONSTANT.KT_CODE_ICDT &&
                payment[CONSTANT.PR_COL_TARGET_CURRENCY] === 'PHP' &&
                this.checkIfBankIsHSBC(payment[CONSTANT.PR_COL_SENDING_ACCOUNT])) {
                wireFileElement[CONSTANT.PR_WIRE_COL_15] = "5677";
            }

            //Philippines Payment Purpose
            if (payment[CONSTANT.PR_COL_SENDING_ACCOUNT] === 'J3USD_HS0750' ||
                payment[CONSTANT.PR_COL_RECEIVING_ACCOUNT] === 'J3USD_HS0750' ||
                payment[CONSTANT.PR_COL_SENDING_ACCOUNT] === 'CWUSD_HS0750' ||
                payment[CONSTANT.PR_COL_RECEIVING_ACCOUNT] === 'CWUSD_HS0750'
            ) {
                wireFileElement[CONSTANT.PR_WIRE_COL_15] = "5677";
            }

            if ((payment[CONSTANT.PR_COL_SENDING_CURRENCY] === 'MYR' || payment[CONSTANT.PR_COL_RECEIVING_CURRENCY] === 'MYR')
            && (this.checkIfBankIsHSBC(payment[CONSTANT.PR_COL_SENDING_ACCOUNT]) || 
            this.checkIfBankIsHSBC(payment[CONSTANT.PR_COL_RECEIVING_ACCOUNT]))) {
                wireFileElement[CONSTANT.PR_WIRE_COL_15] = '16720';
            }

            //SIM: V598583962
            if (payment[CONSTANT.PR_COL_SENDING_ACCOUNT] === 'GWSAR_HS1001' 
            && payment[CONSTANT.PR_COL_RECEIVING_ACCOUNT] === '9IEUR_BA8010') {
                wireFileElement[CONSTANT.PR_WIRE_COL_15] = "/BENEFRES/IC//BRT";
            }

            //SIM: V574580380
            if ((payment[CONSTANT.PR_COL_SENDING_CO] === '7K') && (payment[CONSTANT.PR_COL_SENDING_CURRENCY] === 'USD')
            && (payment[CONSTANT.PR_COL_RECEIVING_CO] === 'B103') && (payment[CONSTANT.PR_COL_RECEIVING_CURRENCY] === 'KES')) {
                wireFileElement[CONSTANT.PR_WIRE_COL_16] = "08400";
            }
            
            //If the payment is ILS, it should be placed in a separate file
            if (this.checkILSFile(payment)) {
                wireILSFileObject.push(wireFileElement);
            } else {
                wireFileObject.push(wireFileElement);
            }
        });

        // Generate File Export Metadata
        let header = WIRE_FILE_PAYMENT_HEADER;
        let delimiter = WIRE_FILE_PAYMENT_DELIMITER;

        // Execute file export logic. 
        //      For AUTO, drop a CSV file to TRS SFTP Kyriba/Inbound Bucket.
        //      For MANUAL, download an CSV file to the users machine.
        if (uploadType === CONSTANT.EXPORT_AUTO) {
            const bucket = config.trsSftpBucket[getStage()];
            const objectKey = 'kyriba/inbound/';
            if (wireFileObject.length > 1) {
                const fileName = "Kyriba_Upload_" + month + "_" + year_YYYY + "_export date_" + fileDate + ".csv";
                const csvString = xlsxParser.createCSV(fileName, delimiter, wireFileObject, header);
                S3Logic.stageFile(objectKey + fileName, bucket, csvString).then((response) => {
                    if (response.status === CONSTANT.FEEDBACK_STAGING_SUCCESS) {
                        this.setState({
                            status: CONSTANT.MODAL_FILE_GENERATION_SUCCESS,
                            statusMsg: "Payments have been automatically exported to Kyriba for processing." 
                        });
                    } else {
                        this.setState({
                            status: response.status,
                            statusMsg: "Automatic Export Issue Occurred: " + response.errorMessage 
                        });
                    }
                });
            }
            if (wireILSFileObject.length > 1) {
                const fileName = "Kyriba_Upload_" + month + "_" + year_YYYY + "_ILS_export date_" + fileDate + ".csv";
                const csvString = xlsxParser.createCSV(fileName, delimiter, wireILSFileObject, header);
                S3Logic.stageFile(objectKey + fileName, bucket, csvString).then((response) => {
                    if (response.status === CONSTANT.FEEDBACK_STAGING_SUCCESS) {
                        this.setState({
                            status: CONSTANT.MODAL_FILE_GENERATION_SUCCESS,
                            statusMsg: "Payments have been automatically exported to Kyriba for processing." 
                        });
                    } else {
                        this.setState({
                            status: response.status,
                            statusMsg: "Automatic Export Issue Occurred: " + response.errorMessage 
                        });
                    }
                });
            }
        } else {
            if (wireFileObject.length > 1) {
                let fileName = "Kyriba Upload " + month + " " + year_YYYY + "_export date" + fileDate + ".csv";
                xlsxParser.writeFileANSI(fileName, delimiter, wireFileObject, header);
            }
            if (wireILSFileObject.length > 1) {
                let fileName = "Kyriba Upload " + month + " " + year_YYYY + " ILS_export date " + fileDate + ".csv";
                xlsxParser.writeFileANSI(fileName, delimiter, wireILSFileObject, header);
            }
            this.setState({
                status: CONSTANT.MODAL_FILE_GENERATION_SUCCESS,
                statusMsg: "Payment file has been downloaded to your device for manual upload." 
            });
        }
    }

    getKyribaTransactionCode = (payment) => {
        let sendingAccount = payment[CONSTANT.PR_COL_SENDING_ACCOUNT];
        let sendingCo = payment[CONSTANT.PR_COL_SENDING_CO];
        let sendingCCY = payment[CONSTANT.PR_COL_SENDING_CURRENCY];
        let receivingAccount = payment[CONSTANT.PR_COL_RECEIVING_ACCOUNT];
        let receivingCo = payment[CONSTANT.PR_COL_RECEIVING_CO];
        let targetCCY = payment[CONSTANT.PR_COL_TARGET_CURRENCY];
        let targetAmount = payment[CONSTANT.PR_COL_TARGET_AMOUNT];

        //Looking for country code 
        let sendingCountryCode = "";
        let receivingCountryCode = "";
        this.state.companyCodeToKyribaBankAccountsMap[sendingCo].forEach(element => {
            if (element["beneficiary"] === sendingAccount) {
                sendingCountryCode = element["countryCode"];
            }
        });

        this.state.companyCodeToKyribaBankAccountsMap[receivingCo].forEach(element => {
            if (element["beneficiary"] === receivingAccount) {
                receivingCountryCode = element["countryCode"];
            }
        });
        
        //TODO: Handle cases where no country code can be found due to unexisted mapping in companyCodeToKyribaBankAccountsMap
        if (targetCCY === "JPY" && 
            this.checkIfBankIsHSBC(sendingAccount) && this.checkIfBankIsHSBC(receivingAccount) &&
            sendingCountryCode === "JP" && receivingCountryCode === "JP") {
                return CONSTANT.KT_CODE_ICBK;
        }

        if (targetCCY === "INR" &&
            sendingCountryCode === "IN" && receivingCountryCode === "IN" &&
            targetAmount < 200000) {
                return CONSTANT.KT_CODE_ICLW;
        }

        if (targetCCY === sendingCCY) {
            if (sendingCountryCode === receivingCountryCode) {
                return CONSTANT.KT_CODE_ICDT;
            } else {
                return CONSTANT.KT_CODE_ICCB;
            }
        } else {
            return CONSTANT.KT_CODE_ICFX;
        }

    }

    checkIfBankIsHSBC = (account) => {
        if (account === null || account === '') {
            return false;
        }

        return account.substr(6,2) === "HS";
    }

    checkIfBankIsRONCB = (account) => {
        if (account === null || account === '') {
            return false;
        }

        return account.includes("RON") && account.includes("CB");
    }

    checkILSFile = (payment) => {
        let sendingCCY = payment[CONSTANT.PR_COL_SENDING_CURRENCY];
        let targetCCY = payment[CONSTANT.PR_COL_RECEIVING_CURRENCY];
        let sendingAccount = payment[CONSTANT.PR_COL_SENDING_ACCOUNT];
        let receivingAccount = payment[CONSTANT.PR_COL_RECEIVING_ACCOUNT];
        if ((sendingCCY === targetCCY) && (sendingCCY === 'ILS')
            && this.checkIfBankIsHSBC(sendingAccount) && this.checkIfBankIsHSBC(receivingAccount)) {
            return true;
        }
        return false;
    }

    // Main handler for updating settlements
    handleUpdate = (elementSelected, name) => {
        Logger.logInfo("handleUpdate elementSelected: " + JSON.stringify(elementSelected)
            + " name: " + name);

        this.setState({
            status: CONSTANT.MODAL_SUBMITTING
        });

        let newData = this.state.distributions;
        let updateSuccessState = CONSTANT.MODAL_PAYMENT_UPDATE_SUCCESS;

        //Update the Selected Element Values
        elementSelected.map((entry, index) => {
            var objIndex = newData.findIndex((obj => obj[CONSTANT.PR_COL_SETTLEMENT_ID] === entry[CONSTANT.PR_COL_SETTLEMENT_ID]));

            if (name === "Export") {
                //For Exporting payment set the status as Exported.
                elementSelected[index][CONSTANT.PR_COL_STATUS] = CONSTANT.PAYMENT_STATUS_EXPORTED;
                elementSelected[index][CONSTANT.PR_COL_EXPORT_DATE] = FormatData.formateNewUTCCurrentTime();
                elementSelected[index][CONSTANT.PR_COL_EXPORTED_BY] = this.state.user;
            }

            // Capture the user who made the latest edits.
            elementSelected[index][CONSTANT.PR_COL_UPDATE_DATE] = FormatData.formateNewUTCCurrentTime();
            elementSelected[index][CONSTANT.PR_COL_UPDATE_BY] = this.state.user;

            newData[objIndex] = entry;
        })
        Logger.logInfo("newData: " + JSON.stringify(newData));

        var parameter = {
            indexString: CONSTANT.INDEX_STRING_APPROVED_PAYMENT_REVIEW,
            userGroup: this.state.userGroup,
            request: name
        };

        Logger.logInfo("elementSelected: " + JSON.stringify(elementSelected));
        APIClient.invoke('POST', 'payment', parameter, elementSelected, (err, requestData) => {
            Logger.logInfo("Response data: " + JSON.stringify(requestData));
            if (!err) {
                switch (requestData.status) {
                    case CONSTANT.RESPONSE_SUCCESS:
                        this.setState({
                            status: updateSuccessState,
                            distributions: requestData.data,
                            elementSelected: []
                        });
                        Logger.logInfo("Update distributions state: " + JSON.stringify(this.state.distributions));
                        break;
                    case CONSTANT.RESPONSE_ERROR:
                        Logger.logError("Error updating payment records with payments list = " + elementSelected.toString());
                        this.setState({
                            status: CONSTANT.MODAL_UPDATE_ERROR,
                            distributions: requestData.data,
                            statusMsg: requestData['errorData'],
                            elementSelected: []
                        });
                        break;
                    case CONSTANT.RESPONSE_SYSTEM_ERROR:
                        Logger.logError("System Error when updating payment records with payments list = " + elementSelected.toString());
                        this.setState({
                            status: CONSTANT.MODAL_SYSTEM_ERROR,
                            statusMsg: JSON.stringify(requestData['errorData'])
                        });
                        break;
                    case CONSTANT.MODAL_REQUEST_ERROR:
                        this.setState({
                            status: CONSTANT.MODAL_REQUEST_ERROR,
                            distributions: requestData.data,
                            statusMsg: JSON.stringify(requestData['errorData'])
                        });
                        break;
                    default:
                }
            } else {
                Logger.logError("Other System Error when updating payment records with payments list = " + elementSelected.toString());
                this.setState({
                    status: CONSTANT.MODAL_OTHER_ERROR,
                    statusMsg: "Other System Error when updating payment data records"
                });
            }
        })
    };

    // Configure column definitions & table configurations for displaying approved settlement data.
    getColumnDefinitionsAndTableConfiguration = () => {
        let columnDefinitions = [];
        let sortableColumns = [];
        let visibleColumnOption = [];
        let firstOption = {};
        firstOption['label'] = 'Properties';
        let filterOptions = [];
        let contentSelectorOptions = [];

        VISIBLE_COLUMN_OPTIONS_APPROVED_PAYMENT.forEach((column) => {
            let columnDefinitionsItem = {};
            let sortableColumnsItem = {};
            let filterColumnOptionItem = {};
            columnDefinitionsItem['id'] = column;
            columnDefinitionsItem['header'] = column;
            columnDefinitionsItem['width'] = 180;

            if (column === CONSTANT.PR_COL_RECEIVING_AMOUNT ||
                column === CONSTANT.PR_COL_SENDING_AMOUNT ||
                column === CONSTANT.PR_COL_SETTLEMENT_USD_EQUIV ||
                column === CONSTANT.PR_COL_TARGET_AMOUNT) {
                //Format USD amount into Display format
                columnDefinitionsItem['cell'] = (item => <Box textAlign="right">
                    {FormatData.formatUSDDisplayAmountAccounting(item[column])}</Box>);
            } else if (column === CONSTANT.PR_COL_UPLOAD_DATE ||
                column === CONSTANT.PR_COL_CREATION_DATE ||
                column === CONSTANT.PR_COL_UPDATE_DATE ||
                column === CONSTANT.PR_COL_APPROVAL_DATE ||
                column === CONSTANT.PR_COL_REJECTION_DATE ||
                column === CONSTANT.PR_COL_EXPORT_DATE) {
                //Format UTC ISO DateString (Used in DDB) to local timezone DateString
                columnDefinitionsItem['cell'] = (item => FormatData.formatISODateToPSTTimezoneDate(item[column]));
            } else if (column === 'Latest Comment') {
                columnDefinitionsItem['cell'] = (item => getLatestComment(item));
            } else if (column === 'Sending Region' || column === 'Receiving Region') {
                columnDefinitionsItem['cell'] = (item => populateRegion(item, column));
            } else if (column === 'Sending Branch') {
                columnDefinitionsItem['cell'] = (item => lookupBranch(item[CONSTANT.PR_COL_SENDING_ACCOUNT],
                    item[CONSTANT.PR_COL_SENDING_CO], this.state.companyCodeToKyribaBankAccountsMap));
            } else if (column === 'Receiving Branch') {
                columnDefinitionsItem['cell'] = (item => lookupBranch(item[CONSTANT.PR_COL_RECEIVING_ACCOUNT],
                    item[CONSTANT.PR_COL_RECEIVING_CO], this.state.companyCodeToKyribaBankAccountsMap));
            } else if (column === 'File Attached'){
                columnDefinitionsItem['cell'] = (item =>
                    hasDocumentAttached(item[CONSTANT.PR_COL_SETTLEMENT_ID],
                        this.state.recordIdToDocumentIdMapping) ? 'Yes' : 'No');
            } else {
                columnDefinitionsItem['cell'] = (item => item[column]);
            }

            // Sortable Columns
            sortableColumnsItem['id'] = column;
            sortableColumnsItem['field'] = column;
            sortableColumns.push(sortableColumnsItem);

            // Filtering Options:
            filterColumnOptionItem['propertyLabel'] = column;
            if (column === 'Sending Region') {
                filterColumnOptionItem['propertyKey'] = CONSTANT.PR_COL_REGION;
            } else {
                filterColumnOptionItem['propertyKey'] = column;
            }
            filterColumnOptionItem['groupValuesLabel'] = column + " values";
            filterColumnOptionItem['values'] = [];

            // Remove columns that are rendered on the front end
            if (column !== 'File Attached') {
                filterOptions.push(filterColumnOptionItem);
            }

            // ColumnDefinitionsItem['minWidth'] = '100px';
            columnDefinitions.push(columnDefinitionsItem);

            // Content Selector Columns
            let contentSelectorItem = {};
            if (HIDDEN_OPTIONS_PENDING_SETTLEMENTS.indexOf(column) !== -1) {
                contentSelectorItem['id'] = column;
                contentSelectorItem['label'] = column;
                contentSelectorItem['editable'] = true;
                contentSelectorItem['visible'] = false;
            } else {
                contentSelectorItem['id'] = column;
                contentSelectorItem['label'] = column;
                contentSelectorItem['editable'] = true;
                contentSelectorItem['visible'] = true;
            }

            contentSelectorOptions.push(contentSelectorItem);
        });

        firstOption['options'] = contentSelectorOptions;
        visibleColumnOption.push(firstOption);

        return {
            visibleColumnOption,
            sortableColumns,
            columnDefinitions,
            filterOptions
        }
    }

    render() {

        /**
         * If the user is in more than 1 IC Settlements LDAP group,
         * Display a Polaris alert to prompt them to remove themselves
         * from all but 1 group.
         */
         if (this.state.icLdapOverlap.length > 1) {
            return ldapMembershipAlert(this.state.icLdapOverlap);
        }

        /**
         * Display the Single Upload Document Module.
         */
        if (this.state.singleUploadVisible === true) {
            return <SingleDocumentModule id={this.state.elementSelected[0][CONSTANT.PR_COL_SETTLEMENT_ID]}
                                         handleDismissSingleUpload ={this.handleDismissSingleUpload}
            />
        }

        let {visibleColumnOption, sortableColumns, columnDefinitions, filterOptions} = this.getColumnDefinitionsAndTableConfiguration();

        return (
            <div>
                {this.state.documentVisible === true && this.state.documentData.length > 0 &&
                <QueryDocumentData distributions={this.state.documentData}
                                   handleDismissDocumentModalClick={this.handleDismissDocumentModalClick}
                                   columnDefinitions={columnDefinitions}
                                   contentSelectorOptions={visibleColumnOption}
                                   filterOptions={filterOptions}
                                   elementSelected={this.state.elementSelected}
                                   onSelectionChange={this.onSelectionChange}
                                   sortableColumns={sortableColumns}
                />}
                {this.state.documentVisible === true && this.state.documentData.length === 0 &&
                <NoDocumentModal handleDismissDocumentModalClick={this.handleDismissDocumentModalClick}/>}
                <Form>
                    <ApprovedPaymentDataContentBlock distributions={this.state.distributions}
                                                     onRowClick={this.onRowClick}
                                                     loading={this.state.loading}
                                                     feedbackMessage={getFeedbackMessage(this.state.status, this.state.statusMsg)}
                                                     elementSelected={this.state.elementSelected}
                                                     contentSelectorOptions={visibleColumnOption}
                                                     sortableColumns={sortableColumns}
                                                     columnDefinitions={columnDefinitions}
                                                     filterOptions={filterOptions}
                                                     userGroup={this.state.userGroup}
                                                     user={this.state.user}
                                                     onSelectionChange={this.onSelectionChange}
                                                     handleUpdateClick={this.handleUpdate}
                                                     handleDismissClick={this.handleDismissClick}
                                                     companyCodeToKyribaBankAccountsMap={this.state.companyCodeToKyribaBankAccountsMap}
                                                     disableOverrideButton={this.state.disableOverrideButton}
                                                     //User Comments Modal Related
                                                     commentVisible={this.state.commentVisible}
                                                     newComment={this.state.newComment}
                                                     userComments={this.state.userComments}
                                                     commentsList={this.state.commentsList}
                                                     handleViewCommentClick={this.handleViewCommentClick}
                                                     handleAddCommentClick={this.handleAddCommentClick}
                                                     handleCommentUpdate={this.handleCommentUpdate}
                                                     //Update Modal Related
                                                     handleViewUpdateWindowClick={this.handleViewUpdateWindowClick}
                                                     handleConfirmUpdateClick={this.handleConfirmUpdateClick}
                                                     updateWindowVisible={this.state.updateWindowVisible}
                                                     accountCategoryToRegionMap={this.state.accountCategoryToRegionMap}
                                                     fxRequiringCurrencyMapping={this.state.fxRequiringCurrencyMapping}
                                                     weekendCalendarMapping={this.state.weekendCalendarMapping}
                                                     holidayCalendarMapping={this.state.holidayCalendarMapping}
                                                     currencyToDecimalDigitMapping={this.state.currencyToDecimalDigitMapping}
                                                     //Batch Update Modal Related
                                                     handleViewBatchUpdateWindowClick={this.handleViewBatchUpdateWindowClick}
                                                     handleConfirmBatchUpdateClick={this.handleConfirmBatchUpdateClick}
                                                     batchUpdateWindowVisible={this.state.batchUpdateWindowVisible}
                                                     //Download Modal Related
                                                     handleViewDownloadWindowClick={this.handleViewDownloadWindowClick}
                                                     downloadWindowVisible={this.state.downloadWindowVisible}
                                                     handleConfirmDownloadClick={this.handleConfirmDownloadClick}
                                                     ifDownloadAll={this.state.ifDownloadAll}
                                                     //Validate Payment Export Modal Related
                                                     validatePaymentExportModalVisible={this.state.validatePaymentExportModalVisible}
                                                     missingBeneficiary={this.state.missingBeneficiary}
                                                     ocmDuplicates={this.state.ocmDuplicates}
                                                     exportPaymentType={this.state.exportPaymentType}
                                                     paymentValidationStatus={this.state.paymentValidationStatus}
                                                     handleConfirmValidateClick={this.handleConfirmValidateClick}
                                                     //File Generation Related
                                                     handleFileGenerationClick={this.handleFileGenerationClick}
                                                     //View Document
                                                     handleQueryDocument={this.handleQueryDocument}
                                                     recordIdToDocumentIdMapping={this.state.recordIdToDocumentIdMapping}
                                                     //Single Document Upload
                                                     handleSingleDocumentClick={this.handleSingleDocumentClick}
                                                     loadedMappings={this.state.loadedMappings}
                    />
                </Form>
            </div>
        );
    }
}

export default QueryApprovedPaymentData;